import React from "react";
import ReactDOM from "react-dom/client";
import SinglePage from "./SinglePage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SinglePage />
  </React.StrictMode>
);
