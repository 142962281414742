import Slider from "./Slider";

export default function Tattoos(data) {
  return (
    <section id="tattoos" className="sp-tattoos">
      <div className="sector-separator st-padding">
        <h1 className="sp-huge-title">
          Art
          <sub className="sp-huge-subtitle">Unser Niveau: Nur Masterpieces</sub>
        </h1>
      </div>

      <Slider
        data={data.data}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
        }}
        className="sp-tattoos__slider"
        width={178}
        height={316}
      />
      <div className="sp-tattoos__content st-padding">
        <h5 className="sp-content-title">
          Handgefertigt von erfahrenen Künstlern
        </h5>
        <p>
          Tauch mal in die Welt der kunstvollen Tattoos ein! Unsere
          einzigartigen Designs vereinen Schönheit und Ausdruckskraft, um Deine
          Persönlichkeit auf unvergleichliche Weise zu unterstreichen. Unsere
          Künstler lassen sich von der Natur, der Kunstgeschichte und den
          neuesten Trends inspirieren, um Dir ein Tattoo zu kreieren, das nicht
          nur ästhetisch ansprechend ist, sondern auch eine tiefere Bedeutung
          trägt. Ob Du ein kleines, diskretes Motiv oder ein großflächiges
          Kunstwerk bevorzugst – bei uns findest Du genau das Richtige, um Deine
          Individualität auszudrücken.
        </p>
        <p className="text-highlight">
          Jedes Tattoo, das unsere Studios verlässt, ist ein individuelles
          Kunstwerk, sorgfältig gestaltet, um Deine einzigartige Geschichte zu
          erzählen. Wir nehmen uns die Zeit, um Dich kennenzulernen und Deine
          Wünsche zu verstehen, damit jedes Detail perfekt auf Dich abgestimmt
          ist. Unsere Künstler setzen ihre Kreativität und ihr handwerkliches
          Können ein, um sicherzustellen, dass jedes Tattoo nicht nur den
          höchsten ästhetischen Ansprüchen genügt, sondern auch eine persönliche
          Verbindung zu Dir hat. Es geht darum, Deine Erlebnisse, Träume und
          Leidenschaften in einem Bild festzuhalten, das für immer ein Teil von
          Dir sein wird.
        </p>
      </div>
    </section>
  );
}
