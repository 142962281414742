export default function Seo(props) {
  return (
    <section className="sp-seo-text st-padding">
      <div className="sp-seo-text__image">
        {props.data
          ? props.data.map((data, i) => (
              <img
                key={i}
                width="311"
                height="249"
                src={data.img}
                alt={data.alt}
                aria-label={data.ariaLabel}
              />
            ))
          : "Loading..."}
      </div>
      <div className="sp-seo-text__content">
        <h5 className="sp-content-title">
          Willkommen bei Tattoo Crown - das Premium-Tattoo-Studio in Hannover!
        </h5>
        <p>
          Seit über einem Jahrzehnt setzen unsere unvergleichlichen Künstler
          Maßstäbe in der Tattoo-Kunst. Mit unübertroffener Erfahrung und
          Leidenschaft für ihre Handwerkskunst verwandeln sie Deine Ideen in
          atemberaubende Kunstwerke. Unser Studio steht für höchste Qualität und
          Exzellenz. Jedes Tattoo, das unsere Türen verlässt, trägt das
          unverkennbare Zeichen von Präzision und Kreativität. Egal, ob Du nach
          einem filigranen Design oder einem ausdrucksstarken Meisterwerk suchst
          - wir erfüllen Deine Wünsche mit Hingabe und Fachkenntnis.
        </p>
        <p className="text-highlight">
          Besuch uns gerne und mach Dir selbst ein Bild von Tattoo Crown und
          überzeuge Dich von unerer unvergleichlichen Qualität und einem
          erstklassigen Service, der Deine Erwartungen übertrifft.
        </p>
        <p className="text-highlight">
          Tattoo Crown – wo Kunst auf Haut trifft und Deine Story ihre Tinte
          findet.
        </p>
      </div>
      <div className="sp-seo-text__backdrop" />
    </section>
  );
}
