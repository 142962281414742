import {
  HandHeart,
  Handshake,
  Hospital,
  Palette,
  Star,
  Target,
} from "lucide-react";

export default function Reasons() {
  return (
    <section id="reasons" className="sp-reasons">
      <div className="sector-separator st-padding">
        <h1 className="sp-huge-title ">
          Warum
          <sub className="sp-huge-subtitle">
            Sechs Gründe, warum Du bei uns richtig bist
          </sub>
        </h1>
      </div>
      <div className="sp-reasons__content">
        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Palette size={38} strokeWidth={1} />
              </span>
              Künstlerische Exzellenz
            </h5>
            <p>
              Eine permanente Entscheidung ist sicherlich keine leichte
              Entscheidung. Dafür möchtest Du ein Tattoostudio wählen, das für
              seine künstlerische Exzellenz bekannt ist.{" "}
              <span className="text-highlight">
                Unser vielfältigeres Portfolio von Tattoo-Stilen und -Designs,
                die die Vielseitigkeit und Kreativität der Künstler zeigen,
                steht für Dich bereit.
              </span>{" "}
              Ob Du nach traditionellen, realistischen, fine-line, Cartoon oder
              individuellen Designs suchst, wir sind ein erstklassiges Studio,
              das Künstler hat, die in der Lage sind, Deine Vision zum Leben zu
              erwecken
            </p>
          </div>
        </div>
        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <HandHeart size={38} strokeWidth={1} />
              </span>
              Erfühlung Deines Tattoo-Wunsches
            </h5>
            <p>
              Wir bieten individuelle Beratungs- und Anpassungsdienste.{" "}
              <span className="text-highlight">
                Einer unserer erfahrenen Künstler wird sich die Zeit nehmen,
                Deine Ideen, Vorlieben und Bedenken zu verstehen, bevor er ein
                maßgeschneidertes Design nach Deinen Vorgaben erstellt.
              </span>{" "}
              Ob Du Dein erstes Tattoo bekommst oder Deine bestehende Sammlung
              erweiterst, persönliche Aufmerksamkeit garantiert eine
              einzigartige und bedeutungsvolle Tattoo-Erfahrung
            </p>
          </div>
        </div>
        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Hospital size={38} strokeWidth={1} />
              </span>
              Hygienestandards
            </h5>
            <p>
              <span className="text-highlight">
                Hygienestandards sind für unser Tattoostudio von höchster
                Bedeutung.
              </span>{" "}
              Wir legen großen Wert darauf, dass unsere Einrichtung stets
              makellos sauber ist, um die Gesundheit und Sicherheit unserer
              Kunden zu gewährleisten. Jeder Arbeitsbereich wird sorgfältig
              desinfiziert und sterilisiert, und wir verwenden nur hochwertige,
              einwegfähige Materialien, um das Risiko von Infektionen zu
              minimieren. Deine Gesundheit steht für uns an erster Stelle, daher
              halten wir uns streng an alle geltenden Hygienevorschriften und
              setzen alles daran, Ihnen ein sicheres und angenehmes
              Tattooerlebnis zu bieten
            </p>
          </div>
        </div>
        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Star size={38} strokeWidth={1} />
              </span>
              Kundenbewertungen
            </h5>
            <p>
              Unser Tattoo-Studio ist stolz darauf, regelmäßig fünf Sterne
              Bewertungen und Empfehlungen von unseren Kunden zu erhalten.{" "}
              <span className="text-highlight">
                Diese positiven Rückmeldungen bestätigen unsere harte Arbeit und
                unser Engagement für höchste Qualität und Kundenzufriedenheit.
              </span>{" "}
              Unsere talentierten Künstler gehen stets auf die Wünsche und
              Bedürfnisse unserer Kunden ein und schaffen einzigartige
              Kunstwerke, die begeistern. Wir sind dankbar für das Vertrauen,
              das uns unsere Kunden entgegenbringen, und freuen uns darauf, auch
              Dich mit unserer Arbeit zu überzeugen
            </p>
          </div>
        </div>
        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Target size={38} strokeWidth={1} />
              </span>
              Komfort und Wohlfühlen
            </h5>
            <p>
              Die Atmosphäre in unserem Studio ist gemütlich und einladend. Wir
              legen großen Wert darauf, dass sich unsere Kunden wohl fühlen und
              eine angenehme Zeit bei uns verbringen. Mit einer warmen und
              freundlichen Einrichtung schaffen wir eine Atmosphäre, in der Du
              Dich entspannst und Deine Tattoo-Erfahrung in vollen Zügen
              genießen kannst. Unser Team ist immer darauf bedacht,
              <span className="text-highlight">
                {" "}
                eine positive und herzliche Stimmung zu schaffen, damit Du Dich
                bei jedem Besuch wie zu Hause fühlst.
              </span>
            </p>
          </div>
        </div>

        <div className="sp-reasons__block">
          <div className="sp-reasons__context">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Handshake size={38} strokeWidth={1} />
              </span>
              Professionalität
            </h5>
            <p>
              <span className="text-highlight">
                Wir sind ein seriöses Tattoostudio mit Professionalität in jedem
                Bereich priorisiert,
              </span>{" "}
              angefangen bei der Sauberkeit ihrer Einrichtungen bis hin zur
              Expertise ihrer Künstler. Suchst Du nach Studios mit erfahrenen
              und geschickten Tätowierern, die Sicherheit und Hygiene
              priorisieren? Dann bist Du bei uns genau richtig
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
