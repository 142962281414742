import { Clock, Mail, MapPinned, PhoneCall } from "lucide-react";
import Socials from "./Socials";

export default function Contact() {
  return (
    <section id="contact" className="sp-contact">
      <div className="sector-separator st-padding">
        <h1 className="sp-huge-title">
          Kontakt
          <sub className="sp-huge-subtitle">
            Melde Dich gerne für ein "hautnahes" Kennenlernen!
          </sub>
        </h1>
      </div>
      <div className="sp-contact__content">
        <h5 className="sp-content-title sp-contact__title">
          Möchtest Du mehr über unsere Tattoos erfahren oder einen Termin
          vereinbaren?
        </h5>
        <p>
          Wir freuen uns darauf, von dir zu hören! Unser Team steht bereit, um
          Deine Fragen zu beantworten und dich bei der Verwirklichung Deiner
          Tattoo-Ideen zu unterstützen.{" "}
          <span className="text-highlight">
            Egal, ob Du bereits eine klare Vorstellung hast oder noch
            Inspiration suchst,
          </span>{" "}
          wir sind hier, um dir zu helfen. Fülle einfach das untenstehende
          Kontaktformular aus oder ruf uns direkt an. Wir sind gespannt darauf,
          dich kennenzulernen und gemeinsam ein einzigartiges Kunstwerk zu
          schaffen.
        </p>
        <div className="sp-contact__call-to-action">
          <div className="sp-contact__working-hours">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Clock size={28} strokeWidth={1} />
              </span>
              Öffnungszeiten
            </h5>
            <p className="text-highlight">
              <b>Mo. - Fr.:</b> 11:00 Uhr - 19:00 Uhr
            </p>
            <p className="text-highlight">
              <b>Sprechstunde</b>
            </p>
            <p className="text-highlight">
              <b>Mo. - Fr.: 18:00 Uhr - 19:00 Uhr</b>
            </p>
            <p className="text-highlight">
              <b>Samstag: Walk-in Day </b> von 12:00 Uhr bis 18:00 Uhr. <br />
              Komm gerne einfach vorbei um spontan ein kleines Tattoo zu
              bekommen!
            </p>
          </div>
          <div className="sp-contact__address">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <MapPinned size={28} strokeWidth={1} />
              </span>
              Unsere Adresse
            </h5>
            <a
              href="https://maps.app.goo.gl/oi7ZdbcA9GN9fKMb8"
              target="_blank"
              className="text-highlight"
              rel="noreferrer"
            >
              Otto-Brenner-Straße 14, 30159 Hannover
            </a>
          </div>
          <div className="sp-contact__phone">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <PhoneCall size={28} strokeWidth={1} />
              </span>
              Telefon
            </h5>
            <a href="tel:004951179025465" className="text-highlight">
              051179025465
            </a>
          </div>
          <div className="sp-contact__mail">
            <h5 className="sp-content-title">
              <span className="sp-flex-icon">
                <Mail size={28} strokeWidth={1} />
              </span>
              E-mail
            </h5>
            <a
              href="mailto:info@crown-tattoo.de?subject=Hallo von Webpage"
              className="text-highlight"
              target="_blank"
              rel="noreferrer"
            >
              info@crown-tattoo.de
            </a>
          </div>
          <Socials />
          <p className="punchline">Kunst auf Haut, Deine Story in Tinte</p>
        </div>
      </div>
    </section>
  );
}
