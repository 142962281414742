import Slider from "./Slider";

export default function Studio(data) {
  return (
    <section id="studio" className="sp-studio">
      <div className="sector-separator st-padding">
        <h1 className="sp-huge-title">
          Studio
          <sub className="sp-huge-subtitle">
            Big things have small beginnings!
          </sub>
        </h1>
      </div>
      <div className="st-padding sp-studio__content">
        <h5 className="sp-content-title">
          Die Leidenschaft führt zum Erfolg: Die Entwicklung unseres
          Tattoostudios in Hannover
        </h5>
        <p>
          Unser Anfang als kleines Studio in der Nordstadt von Hannover war
          bescheiden, aber voller Leidenschaft für die Kunst des Tätowierens.
          Mit einem Team von talentierten Künstlern, die nicht nur ihr Handwerk
          meisterhaft beherrschten, sondern auch eine tiefe Liebe zur Kunst
          hatten, begannen wir unsere Reise. Von Anfang an war es unser Ziel,
          jeden Kundenwunsch mit Sorgfalt und Hingabe zu erfüllen. Wir wussten,
          dass jedes Tattoo eine persönliche Geschichte erzählt und setzten
          alles daran, diese Geschichten in beeindruckende Kunstwerke zu
          verwandeln. Unsere bescheidene Werkstatt wurde schnell zu einem Ort,
          an dem Kreativität und Professionalität Hand in Hand gingen.
        </p>
        <p className="text-highlight">
          Unsere Leidenschaft und unser Engagement trugen schnell Früchte, und
          wir erlebten eine rasante Expansion. Dank unserer treuen Kundenbasis
          und dem unermüdlichen Einsatz unseres Teams konnten wir unsere
          Fähigkeiten und unseren Service stetig verbessern und erweitern.
          Mundpropaganda und begeisterte Empfehlungen brachten uns immer mehr
          Kunden, die unsere einzigartigen und sorgfältig gestalteten Tattoos
          schätzten.
        </p>
        <p>
          Bald darauf öffneten wir die Türen zu unserem großen, aber dennoch
          gemütlichen Tattoo-Studio, das zu einem Anlaufpunkt für
          Tätowierungsbegeisterte aus der ganzen Region wurde. Unser neues
          Studio bot nicht nur mehr Platz, sondern auch eine einladende
          Atmosphäre, in der sich jeder Kunde wohl und gut aufgehoben fühlt.
          Trotz unseres Wachstums bleiben wir unseren Wurzeln treu und setzen
          weiterhin auf Qualität, Kreativität und persönlichen Service. Unser
          Ziel ist es, unseren Kunden ein unvergleichliches Erlebnis zu bieten,
          das sie immer wieder gerne erleben möchten. Jedes Tattoo, das wir
          stechen, wird mit der gleichen Liebe und Sorgfalt gestaltet wie in den
          frühen Tagen unseres kleinen Studios. Wir glauben daran, dass jeder
          Kunde etwas Besonderes verdient, und das spiegelt sich in jedem
          Kunstwerk wider, das unser Studio verlässt.
        </p>
      </div>
      <Slider data={data.data} width={375} height={211} />
    </section>
  );
}
