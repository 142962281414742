export default function Hero(props) {
  return (
    <section className="sp-hero">
      {props.data
        ? props.data.map((data, i) => (
            <img
              key={i}
              width="768"
              height="570"
              src={data.img}
              alt="tattoo-crown-banner"
              aria-label="A maori tattoo being painted on an arm"
            />
          ))
        : "Loading..."}
    </section>
  );
}
