import { Mail } from "lucide-react";

export default function Artists() {
  return (
    <section id="artists" className="sp-artists">
      <div className="sector-separator st-padding">
        <h1 className="sp-huge-title">
          Artists
          <sub className="sp-huge-subtitle">
            Die Seele unserer Kunst sind großartige Menschen
          </sub>
        </h1>
      </div>
      <div className="st-padding sp-artists__container">
        <h5 className="sp-content-title">
          In unserem Tattoo-Studio findest Du eine Auswahl von extrem
          talentierten und erfahrenen Künstlern
        </h5>
        <p>
          Jeder unserer Künstler bringt eine einzigartige Perspektive und
          Stilrichtung mit, um Deine Tattoo-Ideen zum Leben zu erwecken. Unsere
          Künstler zeichnen sich nicht nur durch ihre handwerkliche Fertigkeit
          aus, sondern auch durch ihre Kreativität und ihr Engagement für jedes
          Projekt.{" "}
          <span className="text-highlight">
            Sie nehmen sich die Zeit, um Deine Vorstellungen zu verstehen und
            sie in ein individuelles Kunstwerk zu verwandeln, das Deine
            Persönlichkeit und Deine Geschichte widerspiegelt.
          </span>
        </p>
        <p>
          Egal, ob Du nach traditionellen Motiven, realistischen Porträts oder
          kundenspezifischen Designs suchst, unsere Künstler sind darauf
          spezialisiert, Deine Wünsche zu erfüllen und ein Tattoo zu schaffen,
          das ein Leben lang Freude bereitet. Wir sind stolz darauf, ein Team
          von erstklassigen Künstlern zu haben, die bereit sind, Deine
          Tattoo-Träume Wirklichkeit werden zu lassen. Vereinbare noch heute
          einen Termin und lass dich von ihrem Talent und ihrer Professionalität
          überzeugen.
        </p>
        <div className="sp-artists__wrapper">
          <div className="sp-artists__artist">
            <div className="sp-artists__image">
              <img
                src="./img/artists/lp1.jpg"
                width={313}
                height={313}
                alt="Foto von Lady Petrova"
                aria-label="Foto von Lady Petrova"
              />
            </div>
            <div className="sp-artists__context">
              <h5 className="sp-content-title">Lady Petrova 🇺🇦 - Guest</h5>
              <p>
                Lady Petrova, eine Gasttätowiererin, wird ab dem kommenden Juli
                in unserem Studio arbeiten. Sie ist eine sehr strahlende und
                liebenswerte Person mit hervorragenden Fähigkeiten im Zeichnen
                und einer beeindruckenden Präzision bei der Arbeit. Ihre
                Kunstwerke sind ebenso außergewöhnlich wie ihr herzliches Wesen.
                Wir freuen uns darauf, sie in unserem Team begrüßen zu dürfen
                und sind überzeugt, dass ihre kreativen und präzisen Tattoos
                unsere Kunden begeistern werden.
              </p>
              <p className="text-highlight">
                {" "}
                Wir freuen uns sehr auf die Zusammenarbeit!
              </p>
            </div>
          </div>
          <div className="sp-artists__artist">
            <div className="sp-artists__context">
              <h5 className="sp-content-title">Dr. Shivan</h5>
              <p>
                Dr. Shivan ist nicht nur ein erfahrener Tattoo-Künstler, sondern
                auch eine Quelle der Positivität. Mit einem ständigen Lächeln
                auf den Lippen geht er jede Tattoositzung mit Enthusiasmus und
                Optimismus an. Bekannt für sein geschultes Auge für Details,
                insbesondere in feinen Linien, sorgt Dr. Shivan dafür, dass
                jeder Nadelstich mit Präzision und Raffinesse ausgeführt wird.
                Sein fröhliches Wesen und seine Hingabe zu seinem Handwerk
                machen ihn zu einem Favoriten unter Kunden, die nicht nur
                außergewöhnliche Kunstwerke, sondern auch eine fröhliche und
                inspirierende Erfahrung suchen.
              </p>
            </div>
            <div className="sp-artists__image">
              <img
                src="./img/artists/ds1.jpg"
                width={313}
                height={313}
                alt="Foto von Dr. Shivan"
                aria-label="Foto von Dr. Shivan"
              />
            </div>
          </div>
          <div className="sp-artists__artist">
            <div className="sp-artists__image">
              <img
                src="./img/artists/a1.jpg"
                loading="lazy"
                width={313}
                height={313}
                alt="Foto von Andrea"
                aria-label="Foto von Andrea"
              />
            </div>
            <div className="sp-artists__context">
              <h5 className="sp-content-title">Andrea</h5>
              <p>
                Ein angesehener Tattoo-Künstler, ist bekannt für seine
                unübertroffene Geduld und Zähigkeit in seinem Handwerk. Mit
                einem unerschütterlichen Engagement für Exzellenz geht er jedes
                Tattoo mit akribischer Liebe zum Detail und Präzision an.
                Bekannt für seine Bereitschaft, rund um die Uhr unermüdlich zu
                arbeiten, lässt Andrea nichts unversucht, um sicherzustellen,
                dass jedes von ihm geschaffene Stück ein wahres Meisterwerk ist.
                Seine Hingabe und Leidenschaft zeigen sich in jedem Nadelstich,
                was ihm die Bewunderung von Kunden und Kollegen gleichermaßen
                einbringt.
              </p>
            </div>
          </div>
        </div>

        <div className="sp-artists__job">
          <h1 className="sp-content-title sp-artists__job-title">
            Werde Teil des Elite-Teams bei{" "}
            <span className="text-highlight">Tattoo Crown!</span>
          </h1>
          <p>
            Bist du ein leidenschaftlicher und talentierter Tätowierer auf der
            Suche nach einer unglaublichen Möglichkeit, dein Können unter Beweis
            zu stellen? Dann bist du bei uns genau richtig! Tattoo Crown sucht
            nach herausragenden Gastkünstlern, mit der Möglichkeit auf einen
            festen Vertrag für diejenigen, die wirklich glänzen.
          </p>
          <h5 className="sp-content-title">Warum Tattoo Crown?</h5>
          <ul className="sp-artists__job-description">
            <li>
              <h5 className="text-highlight">Kreative Freiheit: </h5>
              <p>
                Bei Tattoo Crown glauben wir daran, künstlerisches Talent zu
                fördern. Unser Studio ist ein Zufluchtsort für Kreativität, in
                dem dein einzigartiger Stil und deine Vision gefeiert werden.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Modernste Ausstattung:</h5>
              <p>
                Arbeite mit der besten Ausrüstung in einer komfortablen,
                professionellen Umgebung. Unser Studio ist ausgestattet, um
                sicherzustellen, dass du jeden Tag dein bestes Werk schaffen
                kannst.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Kollaboratives Umfeld:</h5>
              <p>
                Schließe dich einem Team gleichgesinnter Künstler an, die
                genauso engagiert für ihr Handwerk sind wie du. Teile Ideen,
                lerne neue Techniken und sei Teil einer lebendigen
                künstlerischen Gemeinschaft.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Erstklassige Lage: </h5>
              <p>
                Im Herzen der Stadt gelegen, zieht Tattoo Crown eine vielfältige
                Kundschaft an und bietet dir die Möglichkeit, an einer Vielzahl
                faszinierender Projekte zu arbeiten.
              </p>
            </li>
          </ul>
          <h5 className="sp-content-title">Vorteile des Jobs</h5>
          <ul className="sp-artists__job-description">
            <li>
              <h5 className="text-highlight">Wettbewerbsfähige Vergütung: </h5>
              <p>
                Genieße eine hervorragende Bezahlung, die deinen Fähigkeiten und
                deiner Erfahrung entspricht, mit Möglichkeiten für Boni und
                Provisionen.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Bereitgestellte Unterkunft: </h5>
              <p>
                Für diejenigen, die von weit her zu uns kommen, bieten wir eine
                komfortable Unterkunft, damit du dich auf das konzentrieren
                kannst, was du am besten kannst – erstaunliche Tattoos kreieren.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Flexible Verträge:</h5>
              <p>
                Ob du auf der Suche nach einem Gastkünstleraufenthalt oder einer
                festen Position bist, wir bieten flexible Vertragsoptionen, die
                zu deinem Lebensstil passen.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Professionelles Wachstum:</h5>
              <p>
                Nutze Möglichkeiten zur beruflichen Weiterbildung,
                einschließlich Workshops, Gastvorträgen und Konferenzen.
              </p>
            </li>
          </ul>
          <h5 className="sp-content-title">Was wir suchen</h5>
          <ul className="sp-artists__job-description">
            <li>
              <h5 className="text-highlight">Außergewöhnliches Talent: </h5>
              <p>
                Künstler mit einem starken Portfolio und einer Leidenschaft für
                Tätowierungen.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Professionelle Einstellung:</h5>
              <p>
                Pünktlichkeit, Zuverlässigkeit und eine positive Ausstrahlung
                sind unerlässlich.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Kundenorientiert: </h5>
              <p>
                Fähigkeit, eine einladende Atmosphäre für Kunden zu schaffen und
                Designs auf ihre Bedürfnisse zuzuschneiden.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Teamplayer: </h5>
              <p>
                Bereitschaft zur Zusammenarbeit und Beitrag zu einem
                unterstützenden Teamumfeld.
              </p>
            </li>
          </ul>
          <h5 className="sp-content-title">Bereit für den nächsten Schritt?</h5>
          <p>
            Wenn du bereit bist, Teil eines dynamischen Teams zu werden, in dem
            deine Kunst wirklich gedeihen kann, möchten wir von dir hören!
            Bewirb dich jetzt, indem du dein Portfolio und eine kurze Einführung
            an unser Team unter [deine E-Mail-Adresse] sendest. Lass uns
            gemeinsam etwas Unglaubliches bei Tattoo Crown schaffen!
          </p>
          <p>
            Erhebe deine Karriere und werde Teil eines Studios, das Kreativität,
            Innovation und Gemeinschaft schätzt. Tattoo Crown ist nicht nur ein
            Arbeitsplatz – es ist ein Ort, an dem Künstler aufblühen. Verpasse
            diese unglaubliche Gelegenheit nicht. Bewirb dich noch heute!
          </p>
          <div className="job-button-wrapper">
            <a
              href="mailto:info@crown-tattoo.de?subject=Bewerbung - Guest Artist"
              class="job-button"
              target="_blank"
              rel="noreferrer"
            >
              <Mail color="#333" />
              <b>Jetzt Bewerben</b>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
