import { CircleX } from "lucide-react";

export default function Impressions({ onClose }) {
  return (
    <div className="sp-impressions-layer">
      <h2>
        Impressum & Datenschutz
        <CircleX className="sp-impressions-layer__close" onClick={onClose} />
      </h2>

      <div className="sp-impressions-layer__content">
        <div className="sp-impressions-layer__block">
          <h4 className="sp-content-title">Verantwortlicher</h4>

          <p>Ali Halawe</p>
          <p>Tattoo Crown</p>
          <p>Otto-Brenner-Straße 14, 30159 Hannover</p>
          <p>0511 79025465</p>
          <p>info@crown-tattoo.de</p>
          <p>www.crown-tattoo.de</p>
        </div>
        <div className="sp-impressions-layer__block">
          <h4 className="sp-content-title">Datenschutzrichtlinie</h4>
          <p>
            Vielen Dank für Ihren Besuch auf der Tattoo Crown Website. Ihre
            Privatsphäre ist uns wichtig, und wir möchten Ihnen mitteilen, wie
            wir Ihre persönlichen Daten schützen und nutzen.
          </p>
          <ol className="sp-impressions-layer__privacy-policy">
            <li>
              <h5 className="text-highlight">Erfassung von Informationen</h5>
              <p>
                Wir verwenden keine Cookies oder Tracking-Technologien auf
                unserer Website. Unser Server erfasst lediglich anonyme Besuche
                und den Datenverkehr, ohne persönliche Benutzerinformationen zu
                sammeln.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Kontaktaufnahme per E-Mail</h5>
              <p>
                Wenn Sie uns per E-Mail kontaktieren, speichert unser Server
                vorübergehend mindestens Ihre E-Mail-Adresse sowie alle anderen
                persönlichen Informationen, die Sie uns mitteilen möchten. Diese
                E-Mails werden von uns automatisch gelöscht, entweder alle zwei
                Monate oder wenn ein Tattoo-Termin vollständig abgeschlossen
                ist.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">
                Keine Weitergabe von Informationen
              </h5>
              <p>
                Wir geben keinerlei Informationen über unsere Benutzer an Dritte
                weiter. Alle auf unserem Server gesammelten Daten werden
                ausschließlich von uns verwendet und nicht an externe Parteien
                weitergegeben.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Datensicherheit</h5>
              <p>
                Unser Server entspricht hohen Sicherheitsstandards, um die
                Sicherheit Ihrer persönlichen Daten zu gewährleisten. Alle von
                Ihnen bereitgestellten Informationen werden nur von uns
                verwendet, um mit Ihnen einen Tattootermin zu vereinbaren.
              </p>
            </li>
            <li>
              <h5 className="text-highlight">Recht auf Löschung</h5>
              <p>
                Sie haben das Recht, die sofortige Löschung Ihrer Daten zu
                verlangen, indem Sie uns kontaktieren.
              </p>
            </li>
          </ol>
        </div>
        <div className="sp-impressions-layer__block">
          <h4 className="sp-content-title">Maßgebliche Rechtsgrundlagen</h4>
          <p>
            Maßgebliche Rechtsgrundlagen nach der DSGVO: Im Folgenden erhalten
            Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
            wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
            Kenntnis, dass neben den Regelungen der DSGVO nationale
            Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
            können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
            maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
            mit.
          </p>
          <p>
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) - die
            Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass
            die Interessen, Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten verlangen, nicht
            überwiegen.
          </p>
          <p>
            Nationale Datenschutzregelungen in Deutschland: Zusätzlich zu den
            Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
            Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
            zum Schutz vor Missbrauch personenbezogener Daten bei der
            Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
            insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
            Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
            Kategorien personenbezogener Daten, zur Verarbeitung für andere
            Zwecke und zur Übermittlung sowie automatisierten
            Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner
            können Landesdatenschutzgesetze der einzelnen Bundesländer zur
            Anwendung gelangen.
          </p>
          <p>
            Hinweis auf Geltung DSGVO und Schweizer DSG: Diese
            Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
            schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG)
            als auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem
            Grund bitten wir Sie zu beachten, dass aufgrund der breiteren
            räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO
            verwendet werden. Insbesondere statt der im Schweizer DSG
            verwendeten Begriffe „Bearbeitung" von „Personendaten",
            "überwiegendes Interesse" und "besonders schützenswerte
            Personendaten" werden die in der DSGVO verwendeten Begriffe
            „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes
            Interesse" und "besondere Kategorien von Daten" verwendet. Die
            gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
            des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
          </p>
        </div>
        <div className="sp-impressions-layer__block">
          <h4 className="sp-content-title">Sicherheitsmaßnahmen</h4>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>
          <p>
            Sicherung von Online-Verbindungen durch
            TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der
            Nutzer, die über unsere Online-Dienste übertragen werden, vor
            unerlaubten Zugriffen zu schützen, setzen wir auf die
            TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und
            Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
            Datenübertragung im Internet. Diese Technologien verschlüsseln die
            Informationen, die zwischen der Website oder App und dem Browser des
            Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die
            Daten vor unbefugtem Zugriff geschützt sind. TLS, als die
            weiterentwickelte und sicherere Version von SSL, gewährleistet, dass
            alle Datenübertragungen den höchsten Sicherheitsstandards
            entsprechen. Wenn eine Website durch ein SSL-/TLS-Zertifikat
            gesichert ist, wird dies durch die Anzeige von HTTPS in der URL
            signalisiert. Dies dient als ein Indikator für die Nutzer, dass ihre
            Daten sicher und verschlüsselt übertragen werden.
          </p>
        </div>
        <div className="sp-impressions-layer__block">
          <h4 className="sp-content-title">Rechte der betroffenen Personen</h4>
          <p>
            Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
            Betroffene nach der DSGVO verschiedene Rechte zu, die sich
            insbesondere aus Art. 15 bis 21 DSGVO ergeben:{" "}
          </p>
          <p>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
            dass die Datenübertragung im Internet (z.B. bei der Kommunikation
            per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
            der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der
            Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
          </p>
          <p>
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die
            unter https://ec.europa.eu/consumers/odr/ aufrufbar ist. Wir sind
            zur Teilnahme am Streitschlichtungsverfahren verpflichtet. Unsere
            E-Mail-Adresse finden Sie in unserem Impressum. Eine Liste mit den
            Kontaktdaten der anerkannten Streitschlichtungsstellen finden Sie
            unter https://ec.europa.eu/consumers/odr/.
          </p>
        </div>
      </div>
    </div>
  );
}
