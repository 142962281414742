import { useState } from "react";
import Impressions from "../components/Impressions";

export function Footer() {
  const [isImpressionsPopupVisible, setImpressionsPopupVisible] =
    useState(false);

  const toggleImpressionsPopup = (e) => {
    e.preventDefault();
    const body = document.body;
    if (!isImpressionsPopupVisible) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    setImpressionsPopupVisible(!isImpressionsPopupVisible);
  };

  return (
    <footer className="sp-footer">
      <section>
        <a href="/#" rel="noreferrer" onClick={toggleImpressionsPopup}>
          Impressum & Datenschutz
        </a>
        {isImpressionsPopupVisible && (
          <Impressions onClose={toggleImpressionsPopup} />
        )}
        <small>
          Diese Webseite verwendet <b>keine Cookies</b>
        </small>
      </section>
    </footer>
  );
}
