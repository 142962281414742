import "./assets/styles/main.scss";
import React, { useEffect, useState } from "react";
import jsonData from "./data/data.json";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Seo from "./components/Seo";
import Hero from "./components/Hero";
import Reasons from "./components/Reasons";
import Socials from "./components/Socials";
import Artists from "./components/Artists";
import Contact from "./components/Contact";
import Studio from "./components/Studio";
import Tattoos from "./components/Tattoos";
import { Footer } from "./components/Footer";

const spTitle = "TATTOO CROWN";
const spSubtitle = "HANNOVER";

function SinglePage() {
  const [pageData, setPageDate] = useState({});

  useEffect(() => {
    setPageDate(jsonData);
    setTimeout(() => {
      let isProgrammaticScrolling = false;

      const handleAnchorClick = () => {
        const anchorLinks = document.querySelectorAll('a[href^="#"]');
        let activeAnchor = null; // Initialize activeAnchor to null

        anchorLinks.forEach((anchor) => {
          anchor.addEventListener("click", () => {
            isProgrammaticScrolling = true;
            activeAnchor = anchor;

            anchorLinks.forEach((a) => {
              a.style.color = "#ffa135";
              a.style.fontWeight = "200";
            });

            activeAnchor.style.color = "white";
            activeAnchor.style.fontWeight = "500";

            setTimeout(() => {
              isProgrammaticScrolling = false;
            }, 700);
          });
        });
      };

      const handleAnchorInView = (entries) => {
        if (!isProgrammaticScrolling) {
          entries.forEach((entry, index) => {
            const anchor = document.querySelector(
              `a[href="#${entry.target.id}"]`
            );
            if (entry.isIntersecting && anchor) {
              document.querySelectorAll('a[href^="#"]').forEach((a) => {
                a.style.color = "#ffa135";
                a.style.fontWeight = "200";
              });

              anchor.style.color = "white";
              anchor.style.fontWeight = "500";
            }
          });
        }
      };

      const observer = new IntersectionObserver(handleAnchorInView, {
        root: null,
        rootMargin: "170px",
        threshold: 0.15,
      });

      document.querySelectorAll("section[id]").forEach((section) => {
        observer.observe(section);
      });

      const handleScroll = () => {
        if (window.scrollY === 0) {
          document.querySelectorAll('a[href^="#"]').forEach((a) => {
            a.style.color = "#ffa135";
            a.style.fontWeight = "200";
          });
        }
      };

      window.addEventListener("scroll", handleScroll);

      handleAnchorClick();

      return () => {
        const anchorLinks = document.querySelectorAll('a[href^="#"]');
        anchorLinks.forEach((anchor) => {
          anchor.removeEventListener("click", handleAnchorClick);
        });
        window.removeEventListener("scroll", handleScroll);
      };
    }, 100);
  }, []);

  return (
    <>
      <nav className="sp-navigation">
        <AnchorLink offset="110" tabIndex="0" href="#tattoos">
          Tattoos
        </AnchorLink>

        <AnchorLink offset="110" tabIndex="0" href="#studio">
          Studio
        </AnchorLink>

        <AnchorLink offset="110" tabIndex="0" href="#artists">
          Artists
        </AnchorLink>

        <AnchorLink offset="110" tabIndex="0" href="#contact">
          Kontakt
        </AnchorLink>
      </nav>
      <header className="sp-header">
        <div className="sp-header__title">
          <h1>{spTitle}</h1>
          <h4>{spSubtitle}</h4>
          <Socials />
        </div>
        <Hero data={pageData.Hero} />
      </header>
      <main className="sp-main">
        <div className="sector-separator  st-padding">
          <h1 className="sp-huge-title">
            Hey
            <sub className="sp-huge-subtitle">Schön, dass Du da bist!</sub>
          </h1>
        </div>
        <Seo data={pageData.SEO} />
        <Tattoos data={pageData.Tattoos} />
        <Studio data={pageData.Studio} />
        <Artists />
        <Reasons />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default SinglePage;
