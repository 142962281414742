import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Slider(props) {
  const { breakpoints } = props;
  return (
    <>
      <Swiper
        style={{ "--swiper-theme-color": "#ffa135" }}
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        navigation
        pagination={{
          clickable: true,
          type: "progressbar",
        }}
        className={`main-slider ${props.className}`}
        breakpoints={breakpoints}
      >
        {props.data
          ? props.data.map((data, i) => (
              <SwiperSlide key={`${data.name}-${i}`}>
                <img
                  src={data.img}
                  width={props.width}
                  height={props.height}
                  alt={data.alt}
                  aria-label={data.ariaLabel}
                />
              </SwiperSlide>
            ))
          : "Loading..."}
      </Swiper>
    </>
  );
}
