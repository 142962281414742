import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

export default function Socials() {
  return (
    <div className="sp-socials">
      <a
        href="https://www.instagram.com/tattoocrown/"
        rel="noreferrer"
        aria-label="instagram icon linked with instagram account"
        target="_blank"
      >
        <FaInstagram size={32} className="text-highlight" />
      </a>
      <a
        href="https://www.facebook.com/TattooCrown.Hannover/"
        rel="noreferrer"
        aria-label="facebook icon linked with facebook account"
        target="_blank"
      >
        <FaFacebook size={32} className="text-highlight" />
      </a>
      <a
        href="https://www.tiktok.com/@tattoocrown_hannover"
        rel="noreferrer"
        aria-label="tiktok icon linked with tiktok account"
        target="_blank"
      >
        <FaTiktok size={32} className="text-highlight" />
      </a>
    </div>
  );
}
